  
<template>
<div>
    <Navbar></Navbar>
    <Breadcrumbs></Breadcrumbs>
    <div class="container">
        <div class="main-body" v-if="employee">
            <div class="row gutters-sm">
                <div class="col-md-4 mb-3">
                    
                    <div class="card">
                        <div class="card-body">
                        <div class="d-flex flex-column align-items-center text-center">
                          <h5>Podešavanja aplikacije</h5>
                            <!-- <div class="profile-img">
                                <img :src="getAvatarUrl(employee)" class="rounded-circle" width="290"/>
                            </div> -->
                        </div>
                        </div>
                    </div>
                    
                    <SettingsLinks :employee=employee :role=employee.user.role></SettingsLinks>

                </div>

                <div class="col-md-8">
                    <settings-general v-if="rootSettingPage"></settings-general>
                    <router-view v-else> </router-view>
                </div>

            </div>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import SettingsLinks from '@/components/settings/SettingsLinks.vue';
import SettingsGeneral from '@/components/settings/SettingsGeneral.vue';
import EmployeesService from '@/service/EmployeesService.js';
import UtilService from '@/service/UtilService.js';
import {mapState} from 'vuex';
import Footer from '@/components/Footer.vue';

export default {

    name: "SettingsPage",

    components: {
        Navbar, SettingsLinks, SettingsGeneral, Footer
    },
    
    data() {
        return {
            employee: null
        }
    },

    computed: {
        ...mapState([
            'user',
        ]),
        rootSettingPage() {
            return (this.$route.name === 'settings');
        }
    },

    created() {
        this.loadEmployee();
    },

    methods: {   
        isMobile: UtilService.isMobile,

        loadEmployee() {
            EmployeesService.getOneEmploeeByUsername((this.username) ? this.username : this.user.username)
            .then((response) => { this.employee = response.data; });
        },

        getAvatarUrl(employee) {
            return employee.user.avatar_url?employee.user.avatar_url:require('@/assets/profilna.png')
        },

        prepareVibrNumber(number) {
          if (number.startsWith("06")) return "3816" + number.slice(2)
        }

    }
}
</script>

<style scoped>
body{
  margin-top:20px;
  color: #1a202c;
  text-align: left;
  background-color: #e2e8f0;
}
.main-body {
  padding: 15px;
}
.card {
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0,0,0,.125);
  border-radius: .25rem;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}
.gutters-sm {
  margin-right: -8px;
  margin-left: -8px;
}
.gutters-sm>.col, .gutters-sm>[class*=col-] {
  padding-right: 8px;
  padding-left: 8px;
}
.mb-3, .my-3 {
  margin-bottom: 1rem!important;
}
.bg-gray-300 {
  background-color: #e2e8f0;
}
.h-100 {
  height: 100%!important;
}
.shadow-none {
  box-shadow: none!important;
}

.contact-icons {
  font-size: 2em; 
}

.contact-icons > a {
  padding: 0 0.25em;
}
</style>