<template>
<div class="card mt-3">
    <ul class="list-group list-group-flush">

        <router-link router-link :to="{ name: 'settings-general'}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
                <font-awesome-icon icon="home"></font-awesome-icon>
                Generalno</h6>
        </router-link>

        <router-link router-link :to="{ name: 'settings-timesheet'}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
                <font-awesome-icon icon="clock"></font-awesome-icon>
                Evidencija</h6>
        </router-link>
        


        <router-link router-link :to="{ name: 'settings-catering'}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
                <font-awesome-icon icon="hamburger"></font-awesome-icon>
                Ketering</h6>
        </router-link>



        <router-link router-link :to="{ name: 'settings-sms'}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap">
            <h6 class="mb-0">
                <font-awesome-icon icon="sms"></font-awesome-icon>
                SMS Servis</h6>
        </router-link>

        <div style="background-color: #f1f1f1" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap">
        <!-- <router-link router-link :to="{ name: 'settings-timesheet'}" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-wrap"> -->
            <h6 class="mb-0">
                <font-awesome-icon icon="wrench"></font-awesome-icon>
                Alat
            </h6>
        <!-- </router-link> -->
        </div>

    </ul>
</div>
</template>

<script>
export default {

    name: "ProfileLinks",

    props: ['role']

}
</script>

<style scoped>
.flex-wrap:hover { color: black; }
.flex-wrap:focus { 
  color: black; 
  -moz-box-shadow: 0 0 1px grey;
  -webkit-box-shadow: 0 0 1px grey;
  box-shadow: 0 0 1px grey;
  }
</style>

<style>
    .option-field {
        /* border: 1px solid rgb(201, 201, 201); */
        border-bottom: 1px solid #eee;
        border-radius: 0.1em;
        padding: 0.5em;
        font-size: 0.7rem;
    }

    .settings-group {
        padding: 0.3em;
        border: 1px solid #eee;
        margin-bottom: 2em;
    }

    .settings-group > .option-field:last-child {
        border-bottom: 0;
    }

    .setting-desc {
        flex: 1 1 auto;
    }

    .settings-value-time {
        width: 114px;
        flex: initial;
    }

    .min-sufix, .hour-sufix, .rsd-sufix {
        position: relative
    }
    .input-sufix::after {
        position: absolute;
        font-size: 1.2em;
        top: 12px;
        right: 20px;
        transition: 0.2s ease all;
    }
    
    .min-sufix::after {
        content: 'min';
    }



    .min-sufix:hover::after, .min-sufix:focus-within:after {
        right: 40px;
    }

    .hour-sufix::after {
        content: 'sati';
    }

    .hour-sufix:hover::after, .hour-sufix:focus-within:after {
        right: 45px;
    }

    .rsd-sufix::after {
        content: 'RSD';
    }

    .rsd-sufix:hover::after, .rsd-sufix:focus-within:after {
        right: 40px;
    }

    .settings-round-value {
        min-width: 114px;
        width: 200px;
        flex: initial;
    }

    .global-settings-parameter {
        display: flex;
        justify-content: flex-end;
    }

    .global-settings-parameter .short-value {
        width: 140px;
    }

</style>